@use "./variables.scss" as *;
@use "./mixins" as *;

html {
  background-color: transparent;
}

.cursor-auto { cursor: auto !important; }
.cursor-default { cursor: default !important; }
.cursor-pointer { cursor: pointer !important; }
.cursor-wait { cursor: wait !important; }
.cursor-text { cursor: text !important; }
.cursor-move { cursor: move !important; }
.cursor-help { cursor: help !important; }
.cursor-not-allowed { cursor: not-allowed !important; }

#root {
  padding-bottom: 60px; // To make room for the bottom action toolbar.
}

// Buttons

.btn, .btn-lg, .btn-group-lg > .btn {
  font-size: inherit;
}

.table>:not(:first-child) {
  border-top: none;
}

label.has-tooltip {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: gray;
}

.tooltip-inner {
  max-width: 400px;

  a {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: gray;
  }
}

.nav {
  &.nav-tabs .nav-link {
    color: inherit;
  }

  .nav-item {
    cursor: pointer;
  }
}

.container-spinner {
  position: relative;
}
input.custom-input.form-control {
  line-height: 0px;
  padding: 9px;
}
.custom-simple-icon.btn i {
  font-weight: $font-weight6;
  vertical-align: middle;
}
.card.sortable-chosen {
  background-color: $color-light-grey !important;
}
.min-height-100px {
  min-height: 100px;
}
.h-40px {
  height: 40px;
}
.custom-cursor-pointer li a,
.custom-cursor-pointer-draggable,
.settings-menu-outer.row .btn {
  cursor: pointer;
}
.settings-menu-outer {
  div {
    margin-bottom: 20px;
  }
  .btn {
    width: $width100P;
    display: block;
    padding: 50px 0px;
    transition: 0.4s;
    text-align: $center;
    background: $color-white;
    color: $color-grey;
    border: none;
    border-radius: 2px;
    &:hover {
      transition: 0.4s;
      color: $color-white;
      transform: scale(1.1);
      background-color: $color-sky-blue-light;
    }
    &:active {
      background: $color-white;
      color: $color-sky-blue-light;
    }
    i {
      font-size: 40px;

      &.fa-sm {
        font-size: 16px;
        top: 4px;
      }
    }
    p {
      margin: 0;
    }

    .fa-stack {
      margin: 6px 0;
    }
  }
}
.custom-alert-span span {
  display: block;
  font-weight: $font-weight6;
}
.custom-sidebar-bg {
  background: $color-white;
  padding: 10px;
  border: 1px solid $color-semi-grey;
  border-radius: 3px;
}
.custom-input-add-border.form-control {
  padding: 0 0 5px 0;
  border-top: 0;
  border-left: 0;
  border-bottom: 1px solid #ccc;
  border-right: 0;
}
.custom-switch.rc-switch-checked.custom-switch-primary {
  background: $color-sky-blue-dark !important;
  border-color: $color-sky-blue-dark !important;
}
ul.breadcrumb-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
    padding-left: 5px;
    &:first-child {
      padding-left: 0;
    }
    text-transform: capitalize;
  }
  li.disable {
    color: #6c757d;
  }
}
.custom-ul {
  padding: 0;
  list-style: none;
  margin: 0px;
  li {
    display: inline-block;
    padding-left: 10px;
    :first-child {
      padding: 0;

    }
  }
}
button.custom-simple-txt-btn.btn.btn-secondary {
  padding: 0;
  background: none;
  color: $color-grey;
  border: none;
  vertical-align: unset;
  &:active, &:hover {
    background: none;
    color: $color-sky-blue-dark;
  }
}
.checkbox-radio-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: $color-white;
  border: 1px solid $color-sky-blue-dark;
}
.checkbox-radio-container input:checked ~ .checkmark {
  background-color: $color-sky-blue-dark;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-radio-container input:checked ~ .checkmark:after {
  display: block;
}
.checkbox-radio-container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-ul-layout {
  padding: 0;
  list-style: none !important;
}
.radio-container {
  .checkmark-radio {
    border-radius: 50%;
    ::after {
      top: 5px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
  }
}
.custom-disabled {
  cursor: not-allowed;
}
.react-datepicker-popper {
  z-index: 999999 !important;
}
.custom-text-color-blue,
button.custom-simple-txt-btn.custom-text-color-blue.btn.btn-secondary {
  color: $color-sky-blue-dark !important;
}
.custom-btm-border {
  border-bottom: 1px solid $color-light-grey;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.custom-fade-text {
  opacity: 0.3;
}
.custom-strike-through {
  position: relative;
  i.simple-icon-ban {
    position: absolute;
    top: 0px;
    font-size: 18px;
    color: $color-red-danger;
    font-weight: 600;
    left: -3px;
  }
}
.custom-font-s30p {
  font-size: 30px;
}
div.rt-noData {
  display: none !important;
}
.btn-as-link {
  padding: 0 !important;
  color: #303030 !important;
  text-decoration: none !important;
}
.btn-as-link:hover {
  color: $color-sky-blue-light !important;
}
.popover-inner {
  hr {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.sky-blue-color { color: $color-sky-blue-dark;}
.column-title {    width: 100% !important;}
.word-break-all{word-break: break-all;}

.display-inline-li li {
  display: inline-block;
  margin-right: 10px;
}

.common-success-button {
  padding: 0 14px;
  line-height: 40px;
  border-radius: 50px;
  background: $success-color-green;
  border: none;
}

.common-success-button:hover {
  background: $color-green-hover-btn;
}

.common-info-button {
  padding: 0;
  line-height: 40px;
  border-radius: 50px;
  background: $color-blue-btn;
  border: none;
}

.common-info-button:hover {
  background: #138496;
}
.button-xlg {
  width: 250px;
}
.button-lg {
  width: 200px;
}
.button-md {
  width: 150px;
}
.button-sm {
  width: 100px;
}
.button-xs {
  width: 50px;
}

.css-bg1rzq-control, .css-1szy77t-control {
  min-height: 42px !important;
  border-radius: 8px !important;
}

.add-new-button {
  border-radius: 50px;
  background: $color-white;
  border: 1px solid $color-blue-btn;
  color: $color-blue-btn;
  padding: 0;
  line-height: 40px;
}
.notification-error {
  word-break: break-all;
}

.ace-editor {
  z-index: 0;
}

.custom-font-s20p {
  font-size: 20px;
}
.font-size-12px {
  font-size: 12px;
}
input:invalid {
  box-shadow: 0px 0px 5px $error-color !important;
}
.section-form input[type="submit"] {
  cursor: not-allowed;
  pointer-events: none;
}
.text-ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 280px;
  overflow: hidden;
  white-space: nowrap;
}
.font-size-15px {
  font-size: 15px;
}

.foreground-color {
  background: var(--foreground-color);
}

.depth-1 {
  @include depth(1);
}

.height-500 {
  height: 500px;
}

.width-500 {
  width: 500px;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (min-width: 1400px) {
  .modal-xl { max-width: 1280px; }
}

@media (min-width: 1600px) {
  .modal-xl { max-width: 1400px; }
}

.input-group-text.seo-warning {
  background-color: color-mix(in srgb, var(--warning-color) 20%, transparent);
  color: var(--warning-color);
}

.rich-text-editor {
  // Show source
  textarea {
    height: 200px;
    padding: 0 !important;
    border: 0;
    font-family: monospace;
  }
  .editor-container {
    .editor-value:focus-visible {
      outline: none;
    }
  }
  .editor-toolbar {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      align-items: center;
      li {
        padding: 8px;
      }
    }
  }
}

.link-helper {
  position: absolute;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0 .5rem 1rem rgba(#000, .175);
  z-index: 1050;
}

.display-flex-grid {
  display: flex;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-wrap: wrap;
}
.modal-footer {
  button {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.device-container {
  margin: 0 auto;

  &.device-tablet {
    max-width: 1024px;
  }

  &.device-phone {
    max-width: 576px;
  }
}
