@use '../../assets/css/sass/variables.scss' as *;

.Test {
  .slate-editor-container {
    > div {
      min-height: 200px;

      &:focus-visible {
        outline: none;
      }
    }

    div[data-slate-node="element"] {
      display: block !important;
    }
  }

  .rich-text-editor .editor-toolbar ul {
    gap: 4px;

    li {
      padding: 8px;
    }

    .mark-active {
      background-color: var(--light-btn-background);
    }
  }
}

.rich-text-editor-hover-menu {
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #fff;
  border-radius: 4px;
  transition: opacity 0.75s;

  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
}
