@use '../../../../assets/css/sass/variables.scss' as *;

.sortable-container {
  &.sortable-chosen {
    .add-new-section {
      visibility: hidden;
    }
  }

  &.sortable-ghost {
    visibility: hidden;
  }

  &.sortable-drag {
    opacity: 1 !important;
  }
}

// This is outside of the block below to reduce its specificity -- it was previously overriding the max-width on images set by the CMS.
.custom-bottom-section-block img {
  max-width: $width100P;
}

.custom-section-block {
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0px 1px 8px 1px $color-semi-grey;
  margin-top: 16px;

  .custom-bottom-section-block {
    overflow: auto;
    min-height: 50px;
    a {
      color: $color-sky-blue-dark;
    }

    section {
      // Using the Advanced Settings tool, users are able to set negative margin, ie to move a section up on top of
      // the section above it. If users do this then the section may not be clickable in the CMS, so the user can't
      // edit it. So we override the margin here.
      margin: 0 !important;
    }

    .SectionContent, .SectionImageGallery, .SectionForm, .SectionMirror, .SectionReviewForm, .SectionReviewList, .SectionCustom, div[class*='mj-column'], div[class^='mj-column'], .SectionComponent {
      position: relative;
      min-height: 50px;
      transition: 0.5s;

      // Using the Advanced Settings tool, users are able to set negative margin, ie to move a section up on top of
      // the section above it. If users do this then the section may not be clickable in the CMS, so the user can't
      // edit it. So we override the margin here.
      margin: 0 !important;

      &:hover .hover-btn {
        transition: 0.5s;
        display: flex !important;
        background: #000000aa;
        cursor: pointer;
      }
      .hover-btn {
        font-size: 12px;
        transition: 0.5s;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1020;
        left: 0;
        right: 0;
        display: none;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        i {
          background-color: $color-white;
          padding: 11px 12px;
          border-radius: 20px;
          border: 1px solid;
        }
      }
    }
  }

  .custom-top-section-block {
    padding: 15px;
    border-bottom: 1px solid $color-light-grey;
    ul {
      li {
        cursor: pointer;
      }
    }
    button {
      font-size: 1rem;
    }
  }

  .section-notes {
    padding: 15px;
    border-bottom: 1px solid $color-light-grey;

    .alert {
      margin: 0 auto;
      max-width: 600px;
      white-space: pre;
      text-wrap: wrap !important;
    }
  }

  .preview-section {
    a {
      pointer-events: none;
    }
  }
}

.edit-section-modal {
  .modal-body {
    max-height: calc(100vh - 164px); // 164px seems to be about the height of the top and bottom margin plus the footer.
    overflow: scroll;
    min-height: 400px; // react-select boxes don't work well unless we have some height.
  }

  &.modal-fullscreen {
    .modal-body {
      max-height: initial;
    }
  }
}

.section-notes-modal {
  .input-group-text {
    cursor: pointer;
  }
}
